<template>
  <div class="graph-section bg-neutral-25">
    <!-- SELECTED KPIS AND GRAPH TYPE SELECTOR -->
    <div class="w-full flex items-center gap-1.5 p-2">
      <!-- Selected KPIs list -->
      <div v-for="(kpi, index) in selectedKpis" :key="`selected-kpi-${index}`" class="selected-kpi">
        <!-- color indicator -->
        <PrimaryMetricLineIcon v-if="graphType === 'line' && index === 0" class="text-icon-normal" />
        <SecondaryMetricLineIcon v-else-if="graphType === 'line' && index === 1" class="text-icon-normal" />
        <svg v-else-if="showMetricColors" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect x="7" y="7" width="10" height="10" rx="2" :fill="getKpiColor(index)" />
        </svg>
        <!-- KPI name -->
        <BaseText type="label" size="sm" class="text-text-muted capitalize" 
        :class="{'pl-1.5': !showMetricColors, 'pr-2': isAdLevel || isPublic}">
          {{ getMetricName(kpi) }}
        </BaseText>
        <!-- Remove button -->
        <button v-if="!isAdLevel && !isPublic" class="group w-6 h-6 flex items-center justify-center" @click="removeMetric(kpi)">
          <TagRemoveIcon :width="16" class="text-text-subdued transition-colors duration-100 group-hover:text-icon-muted"/>
        </button>
      </div>
      <!-- Add Metric Button -->
      <div class="relative" v-on-clickaway="() => { showAddMetricDropdown = false }">
        <button v-if="!isAdLevel && !isPublic" ref="addMetricBtn" class="group flex items-center p-0.5 rounded-md transition-colors"
        :class="addMetricClasses.btn" :disabled="!canAddKpis" @click="showAddMetricDropdown = !showAddMetricDropdown">
          <div class="w-6 h-6 flex items-center justify-center">
            <PlusIcon :width="16" :height="16" class="transition-colors" :class="addMetricClasses.icon" />
          </div>
          <BaseText type="label" size="sm" class="pl-0.5 py-0.5 pr-2">
            Add Metric
          </BaseText>
        </button>
        <!-- Add Metric Dropdown -->
        <transition name="add-metric"
        :leave-active-class="disableAddMetricFadeOut ? '' : 'add-metric-leave-active'"
        :leave-to-class="disableAddMetricFadeOut ? '' : 'add-metric-leave-to'">
          <div v-if="showAddMetricDropdown" class="add-metric-dropdown">
            <PropertySelectDropdown
              search-placeholder="Search Metrics"
              metrics-only
              emit-keys-only
              :selections="selectedKpis"
              @optionSelected="handleAddMetric"
              @close="showAddMetricDropdown = false"
            />
          </div>
        </transition>
      </div>
      <!-- Graph Type Selector -->
      <div v-if="!isPublic" class="ml-auto flex items-center gap-0.5 p-0.5 rounded-md bg-neutral-50">
        <button class="graph-selector-atom" :class="{'active': graphType === 'bar'}"
        @click="changeGraphType('bar')">
          <BarGraphIcon class="text-icon-normal" />
        </button>
        <button v-if="!isAdLevel" class="graph-selector-atom" :class="{'active': graphType === 'line'}" @click="changeGraphType('line')">
          <LineGraphIcon class="text-icon-normal" />
        </button>
        <button class="graph-selector-atom" :class="{'active': graphType === 'grid'}"
        @click="changeGraphType('grid')">
          <GridGraphIcon class="text-icon-normal" />
        </button>
      </div>
    </div>
    <!-- GRAPH AREA -->
    <div ref="graphArea" class="w-full h-full">
      <div v-if="!hasMounted && (selectedKpis.length === 0 || dataItems.length === 0)"
      style="height:360px;" class="w-full h-full flex items-center justify-center">
        <div v-if="resultCount > 0" class="empty-kpi-message shadow-md space-y-1">
          <BaseText class="text-white" type="label" size="sm">
            <span v-if="selectedKpis.length === 0">No Metrics Selected</span>
            <span v-else>No ad groups selected</span>
          </BaseText>
          <BaseText class="text-neutral-alpha-650" size="sm">
            <span v-if="selectedKpis.length === 0">
              To view the graph, add a metric from the top bar or column menu.
            </span>
            <span v-else>
              Select a row to display it on the graph.
            </span>
          </BaseText>
        </div>
      </div>
      <div v-else>
        <LensBarGraphs 
          v-if="graphType === 'bar'"
          :data-items="dataItems"
          :data-summary="dataSummary"
          :selected-kpis="selectedKpis"
          :is-ad-level="isAdLevel"
          @showGroupBreakdown="$emit('showGroupBreakdown', $event)"
          @showDetailsDrawer="$emit('showDetailsDrawer', $event)"
        />
        <LensLineGraphs 
          v-else-if="!isAdLevel && graphType === 'line'"
          :data-items="dataItems"
          :data-summary="dataSummary"
          :selected-kpis="selectedKpis"
          :is-ad-level="isAdLevel"
          :loading-data="loadingData"
          @showGroupBreakdown="$emit('showGroupBreakdown', $event)"
          @showDetailsDrawer="$emit('showDetailsDrawer', $event)"
        />
        <LensGridGraphs
          v-else-if="graphType === 'grid'"
          :data-items="dataItems"
          :data-summary="dataSummary"
          :selected-kpis="selectedKpis"
          :is-ad-level="isAdLevel"
          :is-public="isPublic"
          @showGroupBreakdown="$emit('showGroupBreakdown', $event)"
          @showDetailsDrawer="$emit('showDetailsDrawer', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import kpiColors from '../../../utils/lens/selectedMetricColors'
import { mixin as clickaway } from 'vue-clickaway2'

// Components
import LensBarGraphs from './bar/LensBarGraphs.vue'
import LensLineGraphs from './line/LensLineGraphs.vue'
import LensGridGraphs from './grid/LensGridGraphs.vue'
import PropertySelectDropdown from '../filters/PropertySelectDropdown.vue'

// Icons
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import PlusIcon from '../../globals/Icons/PlusIcon.vue'
import BarGraphIcon from '../../globals/Icons/LensIcons/graphs/BarGraphIcon.vue'
import LineGraphIcon from '../../globals/Icons/LensIcons/graphs/LineGraphIcon.vue'
import GridGraphIcon from '../../globals/Icons/LensIcons/graphs/GridGraphIcon.vue'
import PrimaryMetricLineIcon from '../../globals/Icons/LensIcons/graphs/PrimaryMetricLineIcon.vue'
import SecondaryMetricLineIcon from '../../globals/Icons/LensIcons/graphs/SecondaryMetricLineIcon.vue'

export default {
  name: 'LensReportGraph',
  components: {
    LensBarGraphs,
    LensLineGraphs,
    LensGridGraphs,
    PropertySelectDropdown,
    TagRemoveIcon,
    PlusIcon,
    BarGraphIcon,
    LineGraphIcon,
    GridGraphIcon,
    PrimaryMetricLineIcon,
    SecondaryMetricLineIcon
  },
  mixins: [clickaway],
  props: {
    isPublic: {
      type: Boolean,
      default: false
    },
    isAdLevel: {
      type: Boolean,
      default: false
    },
    hasMounted: {
      type: Boolean,
      default: () => false
    },
    graphType: {
      type: String,
      default: 'bar'
    },
    dataItems: {
      type: Array,
      default: () => []
    },
    resultCount: {
      type: Number,
      default: () => 0
    },
    dataSummary: {
      type: Object,
      default: () => {}
    },
    graphInfo: {
      type: Object,
      default: () => {}
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    canAddKpis: {
      type: Boolean,
      default: false
    },
    loadingData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showAddMetricDropdown: false,
      disableAddMetricFadeOut: false
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    showMetricColors () {
      return this.graphType !== 'grid'
    },
    addMetricClasses () {
      if (this.canAddKpis) {
        if (this.showAddMetricDropdown) {
          return {
            btn: 'bg-neutral-50 text-text-muted',
            icon: 'text-icon-muted'
          }
        } else {
          return {
            btn: 'hover:bg-neutral-50 text-text-muted duration-100',
            icon: 'text-icon-normal group-hover:text-icon-muted duration-100'
          }
        }
      } else {
        return {
          btn: 'text-text-disabled cursor-default duration-300',
          icon: 'text-icon-disabled duration-300'
        }
      }
    }
  },
  methods: {
    changeGraphType (type) {
      this.$emit('update:graphType', type)
    },
    handleAddMetric (key) {
      this.pausePointerEvents('graphArea')
      this.disableAddMetricFadeOut = true
      this.$nextTick(() => {
        this.showAddMetricDropdown = false
        this.$emit('updateSelectedKpis', [...this.selectedKpis, key])
        this.$nextTick(() => { this.disableAddMetricFadeOut = false })
      })
    },
    removeMetric (key) {
      const updatedKpis = this.selectedKpis.filter(kpi => kpi !== key)
      this.pausePointerEvents('addMetricBtn')
      this.$emit('updateSelectedKpis', updatedKpis)
    },
    getKpiColor (index) {
      return kpiColors[index]
    },
    getMetricName (kpi) {
      return this.getMetricLookup?.[kpi]?.name || kpi
    },
    pausePointerEvents (elementRef) {
      // Pause pointer events for the add metric btn to prevent jarring animations when removing right-most metric
      const element = this.$refs[elementRef]
      if (!element) return

      element.style.pointerEvents = 'none'
      let initialMouseX, initialMouseY
      // Reenable events after 4px of mouse movement
      const trackMouseMovement = (event) => {
        if (!initialMouseX || !initialMouseY) {
          initialMouseX = event.clientX
          initialMouseY = event.clientY
          return
        }
        const deltaX = Math.abs(event.clientX - initialMouseX)
        const deltaY = Math.abs(event.clientY - initialMouseY)
        if (deltaX >= 4 || deltaY >= 4) {
          element.style.pointerEvents = 'auto'
          window.removeEventListener('mousemove', trackMouseMovement)
        }
      }
      window.addEventListener('mousemove', trackMouseMovement)
    }
  }
}
</script>

<style scoped>
.graph-section {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  /* height: 100%; */
  min-height: 416px;
  border-radius: 18px;
  padding: 4px;
}
.selected-kpi {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
}
.graph-selector-atom {
  padding: 2px;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: none;
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
}
.graph-selector-atom:hover {
  background-color: #F6F8FA; /* neutral-25 */
}
.graph-selector-atom.active, .graph-selector-atom.active:hover {
  background-color: white;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0.25px 1.5px 0.75px rgba(6, 7, 16, 0.02);
}
.add-metric-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 500;
}

.add-metric-enter-active, .add-metric-leave-active {
  transition: opacity 100ms ease-in-out;
}
.add-metric-enter-from, .add-metric-enter, .add-metric-leave-to {
  opacity: 0;
}
.add-metric-enter-to, .add-metric-leave-from {
  opacity: 1;
}

.empty-kpi-message{
  background: rgba(6, 7, 15, 0.88);
  padding: 16px;
  text-align: center;
  width: 260px;
  height: auto;
  border-radius: 16px;
}
</style>
